import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import List from "../components/list"

import styles from "./list.module.css"

export const query = graphql`
  query($id: String!) {
    sanityPage(id: { eq: $id }) {
      ...CommonPage
    }
    allSanityProject(sort: { fields: position, order: ASC }) {
      nodes {
        id
        name {
          ...Locales
        }
        slug {
          current
        }
        wideimage {
          asset {
            url
          }
        }
        position
      }
    }
  }
`

const ListTemplate = ({
  data: { sanityPage: page, allSanityProject },
  pageContext: { locale },
}) => {
  return (
    <Layout locale={locale}>
      <div className={styles.listContainer}>
        <List locale={locale} projects={allSanityProject.nodes} />
      </div>
    </Layout>
  )
}

export default ListTemplate
