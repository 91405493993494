import React from "react"
import { Link } from "gatsby"

import { thumb } from "../utils/image"
import { projectUrl } from "../utils/url"
import t from "../utils/translate"

import styles from "./list.module.css"

const Item = ({ project, locale }) => (
  <Link to={projectUrl(locale, project)}>
    <div>
      <img src={thumb(project.wideimage)} alt={project.name} />
      <h2>{t(project.name, locale)}</h2>
    </div>
  </Link>
)

const List = ({ projects, locale }) => (
  <div className={styles.list}>
    {projects && projects.map(project => (
      <div key={project.id} className={styles.item}>
        <Item project={project} locale={locale} />
      </div>
    ))}
  </div>
)

export default List
